import { inject, Injectable } from "@angular/core";
import {
    fromEvent,
    Subscription,
    merge,
    BehaviorSubject,
    Observable,
} from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { ILogger } from "../../../core/logging/models/logger.model";
import { LOGGER } from "../../../core/logging/providers/logger.provider";

@Injectable({
    providedIn: "root",
})
export class NetworkStatusService {
    private logger: ILogger = inject(LOGGER);

    // #region isBrowserOnline
    private _isBrowserOnline$ = new BehaviorSubject(navigator.onLine);
    public get isBrowserOnline$(): Observable<boolean> {
        return this._isBrowserOnline$.asObservable();
    }
    // #endregion

    // #region isServerOnline
    private _isServerOnline$ = new BehaviorSubject(true);
    public get isServerOnline$(): Observable<boolean> {
        return this._isServerOnline$.asObservable();
    }
    // #endregion

    // #region isForceOffline
    private _isForceOffline$ = new BehaviorSubject(false);
    public get isForceOffline$(): Observable<boolean> {
        return this._isForceOffline$.asObservable();
    }
    // #endregion

    // #region Combined observable of isBrowserOnline and isForceOffline
    isApiRequestAllowed$: Observable<boolean> = this.isBrowserOnline$.pipe(
        combineLatestWith(this.isForceOffline$),
        map(
            ([isBrowserOnline, isForceOffline]) =>
                isBrowserOnline && !isForceOffline
        )
    );
    // #endregion

    // #region Combined observable of isBrowserOnline, isServerOnline and isForceOffline
    combinedOnlineStatus$: Observable<boolean> = this.isBrowserOnline$.pipe(
        combineLatestWith(this.isServerOnline$, this.isForceOffline$),
        map(
            ([isBrowserOnline, isServerOnline, isForceOffline]) =>
                isBrowserOnline && isServerOnline && !isForceOffline
        )
    );
    // #endregion

    private browserOnlineSubscription$!: Subscription;

    constructor() {
        this.subscribeBrowserOnlineStatus();
    }

    /**
     * Subscribing to browser window online and offline event
     */
    private subscribeBrowserOnlineStatus() {
        this.logger.debug(
            "Subscribing to browser window online and offline event"
        );

        this.browserOnlineSubscription$ = merge(
            fromEvent(window, "online"),
            fromEvent(window, "offline")
        )
            .pipe(map(() => navigator.onLine))
            .subscribe((online) => {
                this.logger.debug("Browser online status: ", online);
                this._isBrowserOnline$.next(online);
            });
    }

    /**
     * Toggle force offline status
     */
    toggleForceOffline(): void {
        const newStatus = !this.isForceOffline();

        this._isForceOffline$.next(newStatus);

        this.logger.debug(`User updated force offline status to: ${newStatus}`);
    }

    /**
     * Set Server Online status
     * @param isOnline - server online status
     */
    setIsServerOnline(isOnline: boolean): void {
        this._isServerOnline$.next(isOnline);
    }

    /**
     * Get browser online status
     * @returns browsers online status
     */
    isBrowserOnline(): boolean {
        return this._isBrowserOnline$.value;
    }

    /**
     * Get force offline status
     * @returns force offline status
     */
    isForceOffline(): boolean {
        return this._isForceOffline$.value;
    }

    /**
     * Get server online status
     * @returns server online status
     */
    isServerOnline(): boolean {
        return this._isServerOnline$.value;
    }

    /**
     * Combined status of isBrowserOnline and isForceOffline
     * @returns Combined status of isBrowserOnline and isForceOffline
     */
    isApiRequestAllowed(): boolean {
        return this._isBrowserOnline$.value && !this._isForceOffline$.value;
    }

    /**
     * Get network online status
     * @returns A combined status of isApiRequestAllowed (browser and isForceOffline) and server
     */
    getCombinedOnlineStatus(): boolean {
        return this.isApiRequestAllowed() && this.isServerOnline();
    }

    /**
     * Unsubscribe subscriptions and complete observables
     */
    onDestroy(): void {
        const start = performance.now();
        this.logger.error(
            "NetworkStatusService - unsubscribing subscriptions and completing observables"
        );

        this.browserOnlineSubscription$.unsubscribe();

        this._isBrowserOnline$.complete();
        this._isServerOnline$.complete();
        this._isForceOffline$.complete();

        const end = performance.now();
        this.logger.debug(
            `NetworkStatusService:onDestroy completed in ${end - start} milliseconds.`
        );
    }
}
